import React, { useState, useEffect } from 'react';
import CategoryTabs from './CategoryTabs';
import { fetchDataFromBackend } from '@/src/communication/socket/dataRequest';
import { SocketEmits } from '@api-types';
import { PortraitSetWithLatestPictureViewModel, CategoryViewModel } from '@models/portraitSetTypes';
import { useAtomValue } from 'jotai';
import { socketAtom } from '@/providers/WebSocketProvider';
import { AIPortraitShowcaseGender } from './AIPortraitShowcase';

const CategoryTabsObserver: React.FC = () => {
  const [data, setData] = useState<{
    categories: CategoryViewModel[];
    portraitSets: PortraitSetWithLatestPictureViewModel[];
  } | null>(null);

  const gender = useAtomValue(AIPortraitShowcaseGender);

  const socket = useAtomValue(socketAtom);

  useEffect(() => {
    if (socket) {
      const fetchData = async () => {
        const [categories, portraitSets] = await Promise.all([
          fetchDataFromBackend<CategoryViewModel[]>(socket, SocketEmits.GetCategories, {}),
          fetchDataFromBackend<PortraitSetWithLatestPictureViewModel[]>(socket, SocketEmits.GetPreviewPicturesByGender, {
            // gender: gender ? gender : undefined,
          }),
        ]);
        setData({ categories, portraitSets });
      };
      fetchData();
    }
  }, [socket, gender]);

  return <div style={{ marginTop: 20, minHeight: '300px' }}>{data && <CategoryTabs data={data} />}</div>;
};

export default CategoryTabsObserver;
