import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import useLocales from '@/locales/useLocales';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import { PortraitSetWithLatestPictureViewModel, CategoryViewModel } from '@models/portraitSetTypes';

// Define the CategoryTabsProps type
type CategoryTabsProps = {
  data: {
    categories: CategoryViewModel[];
    portraitSets: PortraitSetWithLatestPictureViewModel[];
  };
};

const CategoryTabs: React.FC<CategoryTabsProps> = ({ data }) => {
  const { categories, portraitSets } = data;
  const { translate } = useLocales();
  const theme = useTheme();

  // Sort categories by translated name
  const sortedCategories = useMemo(
    () => [...categories].sort((a, b) => translate(a.category).localeCompare(translate(b.category))),
    [categories, translate]
  );

  return (
    <Box sx={{ marginBottom: isMobile ? theme.spacing(2) : theme.spacing(9), padding: theme.spacing(2) }}>
      {sortedCategories
        .filter((category) => category.category !== 'story' && category.category !== 'categories.freestyle')
        .map((category) => (
          <Box key={category.category} sx={{ mt: 6 }}>
            <Box sx={{ display: 'flex', alignItems: 'baseline', marginBottom: isMobile ? 0 : theme.spacing(2) }}>
              <Typography variant={isMobile ? 'h5' : 'h3'} sx={{ fontWeight: 'bold', ml: isMobile ? 0 : 2 }}>
                {translate(category.category)}
              </Typography>
              <Typography variant={isMobile ? 'body2' : 'body1'} sx={{ ml: 2 }}>
                {translate('home.aiGeneratedPortraits')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                overflowY: 'hidden',
                padding: theme.spacing(1),
                marginLeft: theme.spacing(-2),
                marginRight: theme.spacing(-2),
                gap: theme.spacing(2),
                height: '30vh',
                '&:hover': {
                  cursor: 'grab',
                },
                '&:active': {
                  cursor: 'grabbing',
                },
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                userSelect: 'none',
              }}
            >
              {portraitSets
                .filter((set) => set.category === category.category && set.latestPicture)
                .map((picture) => (
                  <LazyLoadImage key={picture.id} picture={picture} theme={theme} />
                ))}
            </Box>
          </Box>
        ))}
    </Box>
  );
};

const LazyLoadImage: React.FC<{ picture: PortraitSetWithLatestPictureViewModel; theme: any }> = ({ picture, theme }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '200px',
  });

  return (
    <Box
      ref={ref}
      sx={{
        overflow: 'hidden',
        flex: '0 0 auto',
        borderRadius: 2,
        boxShadow: theme.shadows[3],

        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Box sx={{ height: '100%', display: 'block', aspectRatio: '9/7', overflow: 'hidden', maxWidth: '300px' }}>
        {inView && (
          <img
            src={picture.latestPicture?.pictureThumbUrl || ''}
            alt={picture.id?.toString() ?? ''}
            draggable="false"
            style={{ height: '100%', display: 'block', objectFit: 'cover' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CategoryTabs;
